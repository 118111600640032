const VR_CATEGORIES = [
  {
    number: 1,
    name: 'テーブルサッカー', // アトラクション名
    description:
      '10種類のボタンを使うテーブルサッカーゲーム。時間内に多くのゴールを入れた方が勝ち', // 説明
    img: 'img-vr-category11.png', // 画像名
    colorClass: 'floorbc-vr2', // シャドウカラー設定
    bkClass: 'bg-bk', // カラー背景設定
    count: '1人モード、2人対決モード',
    age: 'なし',
    height: 'なし',
    weight: 'なし',
    caution1: '飲酒されている方／目の不自由な方／手の不自由な方',
  },
  {
    number: 2,
    name: 'フレンズパン', // アトラクション名
    description:
      '同じモンスターをなぞり、３つ以上繋げて爆発させよう。沢山繋げてランクインを目指そう', // 説明
    img: 'img-vr-category09.png', // 画像名
    colorClass: 'floorbc-vr2', // シャドウカラー設定
    bkClass: 'bg-bk', // カラー背景設定
    count: '2〜4人',
    age: 'なし',
    height: 'なし',
    weight: 'なし',
    caution1: '飲酒されている方／目の不自由な方／手の不自由な方',
    caution4:
      '<p class="list-mark">※本人または、お連れの方の申告によって認定します</p> ',
  },
  {
    number: 3,
    name: 'エッグフレンズ', // アトラクション名
    description:
      '卵に隠れたキャラクターの場所を覚えて、卵をタッチして場所をあてる新感覚神経衰弱ゲーム', // 説明
    img: 'img-vr-category07.png', // 画像名
    colorClass: 'floorbc-vr2', // シャドウカラー設定
    bkClass: 'bg-bk', // カラー背景設定
    count: '2〜4人',
    age: 'なし',
    height: 'なし',
    weight: 'なし',
    caution1:
      'おんぶしてのご利用の方／抱っこしてのご利用の方／飲酒されている方／目の不自由な方／手の不自由な方',
    caution4:
      '<p class="list-mark">※本人または、お連れの方の申告によって認定します</p> ',
  },
  {
    number: 4,
    name: 'モールパンパン', // アトラクション名
    description:
      '画面に現れたモグラを制限時間内にハンマーでたくさんたたいてハイスコアを目指そう！', // 説明
    img: 'img-vr-category18.jpg', // 画像名
    colorClass: 'floorbc-vr2', // シャドウカラー設定
    bkClass: 'bg-bk', // カラー背景設定
    count: '1〜4人',
    age: 'なし',
    height: 'なし',
    weight: 'なし',
    caution1: '飲酒されている方／目の不自由な方／手の不自由な方',
    caution4:
      '<p class="list-mark">※本人または、お連れの方の申告によって認定します</p> ',
  },
  {
    number: 5,
    name: 'フロアシューティング', // アトラクション名
    description:
      '足で地面を踏んでミサイル発射！敵が赤いラインまで下りてくるとゲームオーバー', // 説明
    img: 'img-vr-category17.jpg', // 画像名
    colorClass: 'floorbc-vr2', // シャドウカラー設定
    bkClass: 'bg-bk', // カラー背景設定
    count: '1〜4人',
    age: 'なし',
    height: 'なし',
    weight: 'なし',
    caution1: '飲酒されている方／目の不自由な方／手の不自由な方',
    caution4:
      '<p class="list-mark">※本人または、お連れの方の申告によって認定します</p> ',
  },
  {
    number: 6,
    name: 'フォレストディフェンス', // アトラクション名
    description:
      '空から落ちてくる爆弾を素早くタッチして爆発させよう。時間内に多くの爆弾を爆発させた方が勝ち', // 説明
    img: 'img-vr-category05.png', // 画像名
    colorClass: 'floorbc-vr2', // シャドウカラー設定
    bkClass: 'bg-bk', // カラー背景設定
    count: '1〜2人',
    age: 'なし',
    height: 'なし',
    weight: 'なし',
    caution1: '飲酒されている方／目の不自由な方／手の不自由な方',
    caution4:
      '<p class="list-mark">※本人または、お連れの方の申告によって認定します</p> ',
  },
  {
    number: 7,
    name: 'エスケイプアクション', // アトラクション名
    description:
      'スピード感と想像力が決め手！ 迫りくるシルエットの穴を潜り抜ける新感覚ポージングアクション', // 説明
    img: 'img-vr-category04.png', // 画像名
    colorClass: 'floorbc-vr2', // シャドウカラー設定
    bkClass: 'bg-bk', // カラー背景設定
    count: '1〜2人',
    age: 'なし',
    height: 'なし',
    weight: 'なし',
    caution1:
      'おんぶしてのご利用の方／抱っこしてのご利用の方／飲酒されている方／目の不自由な方／手の不自由な方',
    caution4:
      '<p class="list-mark">足の不自由な方<br />※義足等で歩行がある程度出来れば可能 体験不可</p><p class="list-mark">車椅子の方<br />※歩行が不可能 体験不可</p><p class="list-mark">首や脊髄に異常のある方 体験不可<br />※本人または、お連れの方の申告によって認定します</p> ',
  },
  {
    number: 8,
    name: 'YOKERO', // アトラクション名
    description:
      'カラダひとつで9m×5mのフロアを駆け回る、デジタルアスレチックシリーズ第1弾『YOKERO』', // 説明
    img: 'img-vr-category14.png', // 画像名
    colorClass: 'floorbc-vr2', // シャドウカラー設定
    bkClass: 'bg-bk', // カラー背景設定
    count: '1人',
    age: '6歳以上',
    height: 'なし',
    weight: 'なし',
    caution1:
      '妊娠中の方／ベビーカーでお子様をおつれの方(アクティビティをご利用されていない、別の保護者の方がいれば体験可能)／おんぶしてのご利用の方／抱っこしてのご利用の方／飲酒されている方／心臓の弱い方／目の不自由な方／手の不自由な方／耳の不自由な方',
    caution4:
      '<p class="list-mark">足の不自由な方</p><p class="list-mark">車椅子の方</p><p class="list-mark">首や脊髄に異常のある方 体験不可</p><p class="list-mark">精神障がいをお持ちの方 体験不可<br />※本人または、お連れの方の申告によって認定します</p><p class="list-mark">その他利用上の注意 光刺激で筋肉がけいれん、 意識の喪失などをしたことのある方は利用不可</p> ',
  },
  {
    number: 9,
    name: 'バハムートディスコ feat. SPACE INVADERS', // アトラクション名
    description:
      '5面“450度”のVR映像空間の中で、スペシャルな楽曲に合わせてビートを刻んで遊ぶ、体感型音楽アトラクション', // 説明
    img: 'img-vr-category15.png', // 画像名
    colorClass: 'floorbc-vr2', // シャドウカラー設定
    bkClass: 'bg-bk', // カラー背景設定
    count: '1〜4人',
    age: '6歳以上',
    height: 'なし',
    weight: 'なし',
    caution1:
      '妊娠中の方／ベビーカーでお子様をおつれの方(アクティビティをご利用されていない、別の保護者の方がいれば体験可能)／おんぶしてのご利用の方／抱っこしてのご利用の方／飲酒されている方／心臓の弱い方／目の不自由な方／手の不自由な方／耳の不自由な方',
    caution4:
      '<p class="list-mark">足の不自由な方</p><p class="list-mark">車椅子の方</p><p class="list-mark">首や脊髄に異常のある方 体験不可</p><p class="list-mark">精神障がいをお持ちの方 体験不可<br />※本人または、お連れの方の申告によって認定します</p><p class="list-mark">その他利用上の注意 光刺激で筋肉がけいれん、 意識の喪失などをしたことのある方は利用不可</p> ',
  },
  {
    number: 10,
    name: 'ビートセイバー', // アトラクション名
    description:
      '爽快なリズムに合わせ、前方から来るブロックをプレイヤーが持つ両手の剣を使って切り点数を稼ぐVR音楽ゲーム', // 説明
    img: 'img-vr-category13.png', // 画像名
    colorClass: 'floorbc-vr2', // シャドウカラー設定
    bkClass: 'bg-bk', // カラー背景設定
    count: '1人',
    age: '7歳以上',
    height: 'なし',
    weight: 'なし',
    caution1:
      '妊娠中の方／ベビーカーでお子様をおつれの方(アクティビティをご利用されていない、別の保護者の方がいれば体験可能)／おんぶしてのご利用の方／抱っこしてのご利用の方／飲酒されている方／心臓の弱い方／目の不自由な方／手の不自由な方／耳の不自由な方',
    caution4:
      '<p class="list-mark">足の不自由な方<br />※義足等で歩行がある程度出来れば可能 体験不可</p><p class="list-mark">車椅子の方<br />※歩行が不可能 体験不可</p><p class="list-mark">首や脊髄に異常のある方 体験不可</p><p class="list-mark">精神障がいをお持ちの方 体験不可<br />※本人または、お連れの方の申告によって認定します</p> ',
  },
  {
    number: 11,
    name: 'バスケットボール', // アトラクション名
    description:
      '様々なシュートミッションに挑戦！シュートに視点を置いた疾走感抜群のバスケットボールマシン', // 説明
    img: 'img-vr-category12.png', // 画像名
    colorClass: 'floorbc-vr2', // シャドウカラー設定
    bkClass: 'bg-bk', // カラー背景設定
    count: '1人',
    age: 'なし',
    height: 'なし',
    weight: 'なし',
    caution1:
      '妊娠中の方／ベビーカーでお子様をおつれの方(アクティビティをご利用されていない、別の保護者の方がいれば体験可能)／おんぶしてのご利用の方／抱っこしてのご利用の方／飲酒されている方／心臓の弱い方／目の不自由な方／手の不自由な方／耳の不自由な方',
    caution4:
      '<p class="list-mark">足の不自由な方<br />※義足等で歩行がある程度出来れば可能 体験不可</p><p class="list-mark">車椅子の方<br />※歩行が不可能 体験不可</p><p class="list-mark">首や脊髄に異常のある方 体験不可</p><p class="list-mark">精神障がいをお持ちの方 体験不可<br />※本人または、お連れの方の申告によって認定します</p> ',
  },
  {
    number: 12,
    name: 'バグハンター', // アトラクション名
    description:
      '両手を空に高く上げ、上下に振るとミサイルが発射されます。左右に移動すると相手からの攻撃をかわすことが出来ます', // 説明
    img: 'img-vr-category06.png', // 画像名
    colorClass: 'floorbc-vr2', // シャドウカラー設定
    bkClass: 'bg-bk', // カラー背景設定
    count: '1〜2人',
    age: 'なし',
    height: 'なし',
    weight: 'なし',
    caution1:
      '妊娠中の方／ベビーカーでお子様をおつれの方(アクティビティをご利用されていない、別の保護者の方がいれば体験可能)／おんぶしてのご利用の方／抱っこしてのご利用の方／飲酒されている方／心臓の弱い方／目の不自由な方／手の不自由な方／耳の不自由な方',
    caution4:
      '<p class="list-mark">足の不自由な方<br />※義足等で歩行がある程度出来れば可能 体験不可</p><p class="list-mark">車椅子の方<br />※歩行が不可能 体験不可</p><p class="list-mark">首や脊髄に異常のある方 体験不可</p><p class="list-mark">精神障がいをお持ちの方 体験不可<br />※本人または、お連れの方の申告によって認定します</p> ',
  },
  {
    number: 13,
    name: 'マスゲーム', // アトラクション名
    description:
      '簡単なたし算・ひき算・かけ算・わり算の問題に答えましょう。時間内に多くの点数が取れた方が勝ち。お子様でも楽しみながら取り組める', // 説明
    img: 'img-vr-category08.png', // 画像名
    colorClass: 'floorbc-vr2', // シャドウカラー設定
    bkClass: 'bg-bk', // カラー背景設定
    count: '1〜2人',
    age: 'なし',
    height: 'なし',
    weight: 'なし',
    caution1: '飲酒されている方／目の不自由な方／手の不自由な方',
    caution4:
      '<p class="list-mark">※本人または、お連れの方の申告によって認定します</p> ',
  },
  {
    number: 14,
    name: 'フットピンポン', // アトラクション名
    description:
      '両足を駆使してプレイする新感覚ピンポン。より多くのポイントを獲得した方が勝ち', // 説明
    img: 'img-vr-category10.png', // 画像名
    colorClass: 'floorbc-vr2', // シャドウカラー設定
    bkClass: 'bg-bk', // カラー背景設定
    count: '2〜4人',
    age: 'なし',
    height: 'なし',
    weight: 'なし',
    caution1:
      '妊娠中の方／ベビーカーでお子様をおつれの方(アクティビティをご利用されていない、別の保護者の方がいれば体験可能)／おんぶしてのご利用の方／抱っこしてのご利用の方／飲酒されている方／心臓の弱い方／目の不自由な方／手の不自由な方／耳の不自由な方',
    caution4:
      '<p class="list-mark">足の不自由な方<br />※義足等で歩行がある程度出来れば可能 体験不可</p><p class="list-mark">車椅子の方<br />※歩行が不可能 体験不可</p><p class="list-mark">首や脊髄に異常のある方 体験不可</p><p class="list-mark">精神障がいをお持ちの方 体験不可<br />※本人または、お連れの方の申告によって認定します</p> ',
  },
  {
    number: 15,
    name: 'PHOTON BIKE（フォトンバイク）', // アトラクション名
    description:
      '時空を飛び越え、火星の大地で大ジャンプ！ ゲーム機が躍動し、360度の大迫力の映像で、これまでにない没入感を味わえる体感型VR', // 説明
    img: 'img-vr-category01.png', // 画像名
    colorClass: 'floorbc-vr2', // シャドウカラー設定
    bkClass: 'bg-bk', // カラー背景設定
    count: '1人 (2台で対戦プレイ可)',
    age: '7歳以上',
    height: '140cm以上',
    caution1:
      '体調のすぐれない方／飲酒されている方/肌の弱い方／乗り物に酔いやすい方／妊娠中の方／光刺激で筋肉のけいれん、意識の喪失などをしたことのある方',
    caution2:
      'めまい／閉所恐怖症／呼吸器系疾患／けいれん発作／高血圧／暗所恐怖症／首・背中・腰の疾患／心臓疾患／騒音過敏症',
    caution3:
      '体験中に目の疲労、めまい、平衡感覚の喪失、乗り物酔いに似た症状がでる場合があります。気分が悪くなったり、身体に異常を感じた際は、ただちに利用を中止し、回復するまで十分な休憩をおとりください。\nまた、体験後に上記の不快な症状を感じた場合も、回復するまで十分な休憩をおとりください。安全確保のため、施設内では、スタッフの指示に必ず従ってください。従っていただけない場合にはご利用いただけません。',
  },
  {
    number: 16,
    name: 'PHOTON CAR（フォトンカー）', // アトラクション名
    description:
      'アイテムを駆使して、多彩なステージを疾走！操作に合わせて振動が体に伝わる、リアルなドライビングシミュレーションVR‼', // 説明
    img: 'img-vr-category02.png', // 画像名
    colorClass: 'floorbc-vr2', // シャドウカラー設定
    bkClass: 'bg-bk', // カラー背景設定
    count: '1人 (2台で対戦プレイ可)',
    age: '7歳以上',
    height: '130cm以上',
    caution1:
      '体調のすぐれない方／飲酒されている方/肌の弱い方／乗り物に酔いやすい方／妊娠中の方／光刺激で筋肉のけいれん、意識の喪失などをしたことのある方',
    caution2:
      'めまい／閉所恐怖症／呼吸器系疾患／けいれん発作／高血圧／暗所恐怖症／首・背中・腰の疾患／心臓疾患／騒音過敏症',
    caution3:
      '体験中に目の疲労、めまい、平衡感覚の喪失、乗り物酔いに似た症状がでる場合があります。気分が悪くなったり、身体に異常を感じた際は、ただちに利用を中止し、回復するまで十分な休憩をおとりください。\nまた、体験後に上記の不快な症状を感じた場合も、回復するまで十分な休憩をおとりください。安全確保のため、施設内では、スタッフの指示に必ず従ってください。従っていただけない場合にはご利用いただけません。',
  },
  {
    number: 17,
    name: 'みずほPayPayドームバンジーVR', // アトラクション名
    description:
      'みずほPayPayドーム福岡の屋根の上からドーム内に向かってバンジージャンプ！バーチャル空間で福岡市内のリアルな眺望を楽しみながらバンジー体験をしてみませんか？！', // 説明
    img: 'img-vr-category19.jpg', // 画像名
    colorClass: 'floorbc-vr2', // シャドウカラー設定
    bkClass: 'bg-bk', // カラー背景設定
    count: '1人',
    age: '7歳以上',
    height: '190㎝以下～110㎝以上',
    weight: '100㎏未満',
    caution1:
      '体調のすぐれない方/飲酒されている方/妊娠中の方/医師から激しい運動などの制限を受けている方',
    caution2:
      '骨折や捻挫等の外傷、関節炎/高所恐怖症/閉所恐怖症/暗所恐怖症/骨粗鬆症/けいれん発作/めまい/高血圧/呼吸器/心臓疾患/その他これらに類する持病をお持ちの方',
    caution3:
      '体験中に気分が悪くなるなどの身体に異常を感じた際は、ただちに利用を中止し、回復するまで十分な休憩をおとりください。 また、体験後に上記の不快な症状を感じた場合も、回復するまで十分な休憩をおとりください。安全確保のため、施設内では、スタッフの指示に必ず従ってください。従っていただけない場合にはご利用いただけません。',
  },
];

export default VR_CATEGORIES;
