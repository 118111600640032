/**
 * Type: ページ
 * What: V-World AREA
 */
import React, { useEffect, useState } from 'react';
import { graphql, Link } from 'gatsby';
import { scroller } from 'react-scroll';
import sal from 'sal.js';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import { Btn, Button, Text, Title } from '../components/Btn';
import Image from '../util/Image';
import BreadcrumbComponent from '../components/Breadcrumb';
import SimpleSwiper from '../components/Slider.fade';
import PostListComponent from '../components/PostList/PostList.v-world';
import ContentsList from '../components/ContentsList';
import VR_CATEGORIES from '../config/V-world';

import bgImg01 from '../images/v-world/img-vr-bg01.png';
import bgImg02 from '../images/v-world/img-vr-bg02.png';
import bgImg03 from '../images/v-world/img-vr-bg03.png';
import bgImg04 from '../images/v-world/img-vr-bg04.png';
import bgImg05 from '../images/v-world/img-vr-bg05.png';
import bgImg06 from '../images/v-world/img-vr-bg06.png';
import useMediaQuery from '../util/useMediaQuery';

const pageTitle = 'V-World AREA クレディ・アグリコル';
const pageDescription =
  'E・ZO FUKUOKAの6階と7階、多彩なバーチャルコンテンツが体験できる最新施設V-World AREA クレディ・アグリコル。和の雰囲気に彩られた空間で、様々なバーチャルコンテンツが体験できる最新施設！設置するコンテンツは20種38セットで、九州初進出のアトラクションも多数登場します！';
const pageSlug = 'v-world';
const pageLogo = 'logo-vr.png';

const mq = useMediaQuery;

const sliderDataobj = [
  {
    filename: `main-${pageSlug}01.jpg`,
  },
  {
    filename: `main-${pageSlug}03.jpg`,
  },
  {
    filename: `main-${pageSlug}04.jpg`,
  },
  {
    filename: `main-${pageSlug}05.jpg`,
  },
];

export const query = graphql`
  {
    site {
      siteMetadata {
        uuidVr
        ticket
        ticketReady
      }
    }
  }
`;

// Data Props Template
const Template = ({ data }) => {
  const url = data.site.siteMetadata;
  // State
  const [isModal, setIsModal] = useState(-1);
  const [isModalClose, setIsModalClose] = useState(-1);
  const [isModalMap, setIsModalMap] = useState(false);

  const scrollToTarget = (e, elem, offset) => {
    e.preventDefault();
    const $offset = offset || -110;
    scroller.scrollTo(elem, {
      duration: 1200,
      delay: 0,
      offset: $offset,
      smooth: 'easeOutQuint',
    });
  };

  // モーダル展開
  const onClickModal = (e) => {
    const num = Number(e.currentTarget.dataset.modal);
    setIsModal(num);
  };

  const onClickModalMap = (e) => {
    e.preventDefault();
    setIsModalMap(true);
  };

  // モーダル閉じる
  const onCloseModal = () => {
    setIsModal(-1);
  };

  const onCloseModalMap = () => {
    setIsModalMap(false);
  };

  // モーダル展開
  const onClickModalClose = (e) => {
    const num = Number(e.currentTarget.dataset.modal);
    setIsModalClose(num);
  };

  // モーダル閉じる
  const onCloseModalClose = () => {
    setIsModalClose(-1);
  };

  // Effect
  useEffect(() => {
    // スクロールアクション
    sal({
      threshold: 0.1,
    });
  });

  // モーダルコンテンツ
  const ModalContent = () => {
    const img = VR_CATEGORIES[isModal] && VR_CATEGORIES[isModal].img;
    const name = VR_CATEGORIES[isModal] && VR_CATEGORIES[isModal].name;
    const colorClass =
      VR_CATEGORIES[isModal] && VR_CATEGORIES[isModal].colorClass;
    const count = VR_CATEGORIES[isModal] && VR_CATEGORIES[isModal].count;
    const age = VR_CATEGORIES[isModal] && VR_CATEGORIES[isModal].age;
    const height = VR_CATEGORIES[isModal] && VR_CATEGORIES[isModal].height;
    const weight = VR_CATEGORIES[isModal] && VR_CATEGORIES[isModal].weight;
    const caution1 = VR_CATEGORIES[isModal] && VR_CATEGORIES[isModal].caution1;
    const caution2 = VR_CATEGORIES[isModal] && VR_CATEGORIES[isModal].caution2;
    const caution3 = VR_CATEGORIES[isModal] && VR_CATEGORIES[isModal].caution3;
    const caution4 = VR_CATEGORIES[isModal] && VR_CATEGORIES[isModal].caution4;
    return (
      <div className={`modal e-modal ${isModal > -1 ? 'is-active' : ''}`}>
        {/* eslint-disable-next-line max-len */}
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <div className="modal-background" onClick={onCloseModal} />
        <button
          type="button"
          className="delete"
          aria-label="close"
          data-sal="zoom-in"
          data-sal-delay="300"
          data-sal-easing="ease-out-expo"
          onClick={onCloseModal}
        />
        <div className={`modal-content c-${colorClass}`}>
          <div className="e-modal-image">
            <figure className="image">
              <Image filename={img || 'now-printing.jpg'} />
            </figure>
          </div>
          <div className="e-modal-content">
            <h3 className="ttl c-4">{name}</h3>
            <h4>アトラクション注意事項</h4>
            <ul>
              <li>
                体験人数:
                <b>{count}</b>
              </li>
              <li>
                年齢制限:
                <b>{age}</b>
              </li>
              <li>
                身長制限:
                <b>{height}</b>
              </li>
              {weight ? (
                <li>
                  体重制限:
                  <b>{weight}</b>
                </li>
              ) : null}
            </ul>
            {caution1 && (
              <>
                <h5>
                  <span>ご利用いただけない方</span>
                </h5>
                <p>{caution1}</p>
                {caution4 && (
                  <div dangerouslySetInnerHTML={{ __html: caution4 }} />
                )}
              </>
            )}
            {caution2 && (
              <>
                <h5>
                  <span>以下の症状の方はご利用できません</span>
                </h5>
                <p>{caution2}</p>
              </>
            )}
            {caution3 && (
              <>
                <h5>
                  <span>注意喚起</span>
                </h5>
                <p>{caution3}</p>
              </>
            )}
          </div>
        </div>
      </div>
    );
  };
  return (
    <Layout pageSlug={pageSlug} bg="bg-17">
      <SEO title={pageTitle} description={pageDescription} />
      <BreadcrumbComponent hierarchy={2} bread2title={pageTitle} />
      <section className="pageMain bs-76">
        <div className="kv-wrap bg-17">
          <div className="kv-inner">
            <SimpleSwiper data={sliderDataobj} />
          </div>
          <div className="container">
            <div className="inner">
              <div className="pageMain-header">
                <div className="floorNo c-76">
                  <span>6</span>
                  <span>F</span>
                </div>
                <h2 className="ttl">
                  <Image src={pageLogo} alt={pageTitle} />
                </h2>
              </div>

              <div className="overview mb30">
                <p className="text">
                  和の雰囲気に彩られた空間で、様々なバーチャルコンテンツが体験できる最新施設！
                </p>
              </div>
              {url.ticketReady === '1' ? (
                <div
                  className="btn-wrap mb-0 pb-0"
                  data-sal="slide-up"
                  data-sal-easing="ease-out-expo"
                >
                  <Button
                    className="btn-buy"
                    href={`${url.ticket}#/order?lang=ja&id=${url.uuidVr}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    チケットを探す
                  </Button>
                </div>
              ) : null}
              {/* <div className="btn-group mt-5">
                <Link
                  to="/news/info/20230311252/"
                  className="btn-def caution-ticket-btn narrow"
                  data-sal="slide-up"
                  data-sal-easing="ease-out-expo"
                >
                  <span>新型コロナウイルス感染防止の対策について</span>
                </Link>
              </div> */}

              <div className="youtube-wrap mt-6 mb-6 cn-hide">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/Y21Pnpm4cMQ"
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </div>
              <div
                className="btn-wrap mb-6 pb-0"
                data-sal="slide-up"
                data-sal-easing="ease-out-expo"
              >
                <a
                  href="#"
                  className="btn-regular btn-regular"
                  onClick={(e) => onClickModalMap(e)}
                >
                  <span>フロアマップ</span>
                  <svg
                    viewBox="0 0 490.8 490.8"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M135.685,3.128c-4.237-4.093-10.99-3.975-15.083,0.262c-3.992,4.134-3.992,10.687,0,14.82l227.115,227.136L120.581,472.461c-4.237,4.093-4.354,10.845-0.262,15.083c4.093,4.237,10.845,4.354,15.083,0.262c0.089-0.086,0.176-0.173,0.262-0.262l234.667-234.667c4.164-4.165,4.164-10.917,0-15.083L135.685,3.128z" />
                    <path d="M128.133,490.68c-5.891,0.011-10.675-4.757-10.686-10.648c-0.005-2.84,1.123-5.565,3.134-7.571l227.136-227.115L120.581,18.232c-4.171-4.171-4.171-10.933,0-15.104c4.171-4.171,10.933-4.171,15.104,0l234.667,234.667c4.164,4.165,4.164,10.917,0,15.083L135.685,487.544C133.685,489.551,130.967,490.68,128.133,490.68z" />
                  </svg>
                </a>
              </div>

              <div className="section-link">
                <ul className="bs-76">
                  {/*
                  <li>
                    <a
                      href="#topics"
                      onClick={(e) => scrollToTarget(e, 'topics', -68)}
                    >
                      <span
                        className="arrow"
                      >
                        TOPICS
                      </span>
                    </a>
                  </li>
                  */}
                  <li>
                    <a
                      href="#activity"
                      onClick={(e) => scrollToTarget(e, 'activity')}
                    >
                      <span className="arrow">ACTIVITY</span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="#price"
                      onClick={(e) => scrollToTarget(e, 'price')}
                    >
                      <span className="arrow">料金</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <PostListComponent />
      <section className="section sc-activity bg-17 bs-76">
        <div className="container">
          <h2 className="headline" id="activity">
            <span>Activity</span>
          </h2>
          <div className="sc-columns now-event mb80">
            {VR_CATEGORIES.map((categories, index) => (
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
              <div
                className={`col col-4 ${categories.colorClass}`}
                key={categories.name}
                data-modal={index}
                data-sal="slide-up"
                data-sal-easing="ease-out-expo"
                onClick={(e) => onClickModal(e)}
              >
                <ContentsList {...categories} />
              </div>
            ))}
          </div>
        </div>
      </section>
      <section className="section sc-price" id="price">
        <div className="container">
          <div className="inner-slim">
            <div
              className="ov-image06"
              data-sal="fade"
              data-sal-duration="600"
              data-sal-easing="ease-in-out"
              data-sal-delay="300"
            >
              <img src={bgImg06} alt="" />
            </div>
            <h3 className="headline">
              <span>Price</span>
            </h3>
            <p className="tac">
              各日程のレギュラー・ピークは
              <br className="sp" />
              空席情報カレンダーでご確認ください。
            </p>
            <p className="tac">
              <a href="/ticket/" className="tx-link">
                空席情報カレンダーはこちら
              </a>
            </p>
            <div className="table-base">
              <table>
                <tbody>
                  <tr>
                    <th>2時間チケット</th>
                    <td className="is-size-6">
                      <p className="fwb">23歳以上</p>
                      <dl className="base-dl">
                        <dt>ピーク</dt>
                        <dd>
                          <b>3,000円</b>
                        </dd>
                      </dl>
                      <dl className="base-dl">
                        <dt>レギュラー</dt>
                        <dd>
                          <b>2,800円</b>
                        </dd>
                      </dl>
                      <p className="fwb mt30">13歳～22歳</p>
                      <dl className="base-dl">
                        <dt>ピーク</dt>
                        <dd>
                          <b>2,300円</b>
                        </dd>
                      </dl>
                      <dl className="base-dl">
                        <dt>レギュラー</dt>
                        <dd>
                          <b>2,200円</b>
                        </dd>
                      </dl>
                      <p className="fwb mt30">12歳以下</p>
                      <dl className="base-dl">
                        <dt>ピーク</dt>
                        <dd>
                          <b>1,800円</b>
                        </dd>
                      </dl>
                      <dl className="base-dl">
                        <dt>レギュラー</dt>
                        <dd>
                          <b>1,700円</b>
                        </dd>
                      </dl>
                    </td>
                  </tr>

                  <tr>
                    <th>30分チケット</th>
                    <td className="is-size-6">
                      <p className="fwb">23歳以上</p>
                      <dl className="base-dl">
                        <dt>ピーク</dt>
                        <dd>
                          <b>1,200円</b>
                        </dd>
                      </dl>
                      <dl className="base-dl">
                        <dt>レギュラー</dt>
                        <dd>
                          <b>1,000円</b>
                        </dd>
                      </dl>
                      <p className="fwb mt30">13歳～22歳</p>
                      <dl className="base-dl">
                        <dt>ピーク</dt>
                        <dd>
                          <b>950円</b>
                        </dd>
                      </dl>
                      <dl className="base-dl">
                        <dt>レギュラー</dt>
                        <dd>
                          <b>800円</b>
                        </dd>
                      </dl>
                      <p className="fwb mt30">12歳以下</p>
                      <dl className="base-dl">
                        <dt>ピーク</dt>
                        <dd>
                          <b>600円</b>
                        </dd>
                      </dl>
                      <dl className="base-dl">
                        <dt>レギュラー</dt>
                        <dd>
                          <b>500円</b>
                        </dd>
                      </dl>
                    </td>
                  </tr>

                  {/* <tr>
                    <th>V-World AREA クレディ・アグリコル 2時間チケット<br />＋みずほPayPayドームバンジーVR 1回チケット</th>
                    <td className="is-size-6">
                      <p className='fwb'>23歳以上</p>
                      <dl className="base-dl">
                        <dt>ピーク</dt>
                        <dd>
                          <b>3,300円</b>
                        </dd>
                      </dl>
                      <p className='fwb mt30'>13歳～22歳</p>
                      <dl className="base-dl">
                        <dt>ピーク</dt>
                        <dd>
                          <b>2,700円</b>
                        </dd>
                      </dl>
                      <p className='fwb mt30'>12歳以下</p>
                      <dl className="base-dl">
                        <dt>ピーク</dt>
                        <dd>
                          <b>2,000円</b>
                        </dd>
                      </dl>
                    </td>
                  </tr>
                  <tr>
                    <th>V-World AREA クレディ・アグリコル 30分チケット<br />＋みずほPayPayドームバンジーVR 1回チケット</th>
                    <td className="is-size-6">
                      <p className='fwb'>23歳以上</p>
                      <dl className="base-dl">
                        <dt>ピーク</dt>
                        <dd>
                          <b>1,700円</b>
                        </dd>
                      </dl>
                      <p className='fwb mt30'>13歳～22歳</p>
                      <dl className="base-dl">
                        <dt>ピーク</dt>
                        <dd>
                          <b>1,450円</b>
                        </dd>
                      </dl>
                      <p className='fwb mt30'>12歳以下</p>
                      <dl className="base-dl">
                        <dt>ピーク</dt>
                        <dd>
                          <b>1,100円</b>
                        </dd>
                      </dl>
                    </td>
                  </tr> */}
                </tbody>
              </table>
              <p className="list-mark mb0 attention">
                ※アクティビティ体験をされない方は入場無料
              </p>
              <p className="list-mark mb0">
                ※3歳以下のお客様については無料となりますが、保護者同伴が必要となります。
              </p>
              <p className="list-mark mb0">
                ※6歳以下のお客様はご利用いただけないアクティビティがございます。
              </p>
              <p className="list-mark mb0">
                ※18時以降の15歳以下の入場は保護者同伴が必要となります。
              </p>
            </div>
            <article>
              <h4 className="title is-5">
                以下に該当する方はご利用いただけません
              </h4>
              <p>
                体調がすぐれない/乗り物に酔いやすい/妊娠中/めまい/閉所恐怖症/呼吸器系疾患/けいれん発作
                <br />
                /高血圧/暗所恐怖症/首・背中腰の疾患/肌が弱い/騒音過敏症/飲酒されている
              </p>
              <p className="list-mark">
                ※6歳以下の方はご利用いただけないアクティビティがございます。
              </p>
              <p className="list-mark">
                ※ご自身での自立歩行が難しい方はご利用いただけないアクティビティがございます。
              </p>
              <p className="list-mark">
                ※お体の不自由な方は、スタッフにお尋ねください。
              </p>
              <p className="list-mark">
                ※その他ご利用により悪化する恐れのある症状をお持ちの方はご利用をご遠慮ください。
              </p>
            </article>
            <article>
              <h4 className="title is-5">注意事項</h4>
              <ul className="list-base">
                <li>
                  館内状況により、ご入場をお待ちいただく場合がございます。
                </li>
                <li>
                  当施設内での怪我、損失、損害、トラブルなどにつきまして、弊社の責めに帰すべき事由がある場合を除き、弊社は一切の責任を負いません。
                </li>
                <li>
                  故障、メンテナンスなどの理由により予告なく一部機器の稼働を中止する場合がございます。
                </li>
                <li>
                  撮影には一脚、三脚、自分撮りスティック等の補助機材は使用できません。
                </li>
                <li>
                  お手回り品の放置はご遠慮ください。放置されたお荷物は拾得物としてお預かりさせていただくことがございます。
                </li>
                <li>
                  通路や共有スペースの専有、列への割り込み、物品などの販売や展示、その他迷惑行為や危険な行為は禁止です。
                </li>
                <li>
                  エリア内への食べ物の持ち込み、アクティビティ利用中の飲食は禁止です。
                </li>
                <li>
                  スタッフの指示に従っていただけない場合は、入場をお断りすることがございます。
                </li>
              </ul>
              <h5 className="ttl-strip">アクティビティご利用にあたって</h5>
              <p>
                各アクティビティの利用制限・利用上の注意を読み、正しい遊び方を守ってご利用ください。
                <br />
                激しい動きをするアクティビティもございますので体をほぐしてから体験をしてください。
                <br className="sp" />
                また、
                体調が優れない方、自信のない方は無理をしないようお願いします。
              </p>
            </article>
          </div>
        </div>
        <div
          className="ov-image01"
          data-sal="slide-right"
          data-sal-duration="600"
          data-sal-easing="ease-in-out"
          data-sal-delay="60"
        >
          <img src={bgImg01} alt="" />
        </div>
        <div
          className="ov-image02"
          data-sal="slide-right"
          data-sal-duration="600"
          data-sal-easing="ease-in-out"
          data-sal-delay="120"
        >
          <img src={bgImg02} alt="" />
        </div>
        <div
          className="ov-image03"
          data-sal="slide-right"
          data-sal-duration="600"
          data-sal-easing="ease-in-out"
          data-sal-delay="180"
        >
          <img src={bgImg03} alt="" />
        </div>
        <div
          className="ov-image04"
          data-sal="slide-left"
          data-sal-duration="600"
          data-sal-easing="ease-in-out"
          data-sal-delay="60"
        >
          <img src={bgImg04} alt="" />
        </div>
        <div
          className="ov-image05"
          data-sal="slide-left"
          data-sal-duration="600"
          data-sal-easing="ease-in-out"
          data-sal-delay="180"
        >
          <img src={bgImg05} alt="" />
        </div>
      </section>
      <ModalContent />
      <div
        className={`${
          isModalMap ? 'modal map-modal is-active' : 'modal map-modal'
        }`}
      >
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <div className="modal-background" onClick={onCloseModalMap} />
        <button
          type="button"
          className="delete"
          aria-label="close"
          data-sal="zoom-in"
          data-sal-delay="300"
          data-sal-easing="ease-out-expo"
          onClick={onCloseModalMap}
        />
        <div className="modal-card">
          <section className="modal-card-body">
            <Image filename="img-v-world-map.jpg" />
          </section>
        </div>
      </div>
    </Layout>
  );
};

export default Template;
